<template>
    <div class="animated fadeIn" id="content">
    <div id="snackbar"></div>       
    <b-row>
      <b-col sm="12">
        <b-card id="form-card">
          <div slot="header">
            <strong>Areas </strong> <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit" id="form">
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="country">Country <span class="text-danger">*</span></label>
                <b-form-select required v-model="CountryID" 
                  @change.native="getStateList($event.target.value)" id="month1"
                  value-field="CountryID"
                  text-field="CountryName"
                  :plain="true"
                  :options=countries>
                     <template slot="first">
                        <option :value="0" disabled>-- Select Country --</option>
                    </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="state">State <span class="text-danger">*</span></label>
                <b-form-select required v-model="StateID" 
                @change.native="getCityList($event.target.value)" id="month1"
                  :plain="true"
                  value-field="StateID"
                  text-field="StateName"
                  :options=states>
                  <template slot="first">
                        <option :value="0" disabled>-- Select State --</option>
                    </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group>
                <label for="city">City <span class="text-danger">*</span></label>
                <b-form-select required 
                @change.native="getAreaList($event.target.value)" 
                v-model="area.CityID" id="month1"
                  :plain="true"
                  value-field="CityID"
                  text-field="CityName"
                  :options=cities>
                  <template slot="first">
                        <option :value="0" disabled>-- Select City --</option>
                    </template>
                </b-form-select>
              </b-form-group>
            </b-col>            
            <b-col sm="6">
              <b-form-group>
                <label for="title">Title <span class="text-danger">*</span></label>
                <b-form-input type="text" required v-model="area.AreaName" id="title" placeholder="Area Name"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
             <b-col sm="6">
              <b-form-group>
                <label for="code">Postal Code <span class="text-danger">*</span></label>
                <b-form-input  type="number" step="any" required v-model="area.PostalCode" id="code" placeholder="Postal Code(PIN Code)"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <label for="status">Status</label>
                <br>
                <c-switch class="mx-1" color="primary" v-model="area.AreaIsActive" defaultChecked variant="3d" label v-bind="labelIcon" />
              </b-form-group>
            </b-col>
            </b-row>

          <b-row>
            
          </b-row>
          <b-row>
              <b-col sm="6">
                  <b-button type="submit" size="sm" variant="primary"><i class="fa fa-dot-circle-o"></i> {{ commontext.submitButton }}</b-button>
              </b-col>

              <b-col sm="6">
                  <b-button @click="resetForm" size="sm" variant="success"><i class="fa fa-dot-circle-o"></i> Reset</b-button>
              </b-col>
          </b-row>
          </b-form>
        </b-card>

         <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of Areas
        <div class="card-header-actions">
          
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
          <div slot="AreaIsActive" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.AreaIsActive" variant="3d" label v-bind="labelIcon" @change.native="changeStatus(props.row.AreaID)"/>
          </div>
         <div slot="AreaID" slot-scope="props">
            <i class="fa fa-edit" @click="editArea(props.row.AreaID)"></i>
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>
      </b-col>
    </b-row>
    <b-modal id="ModalConfirmationOnSubmit" ref="ModalConfirmationOnSubmit" @ok="handleModalOkay" :title="modalObject.title" class="center-footer" footer-class="justify-content-center border-0" cancel-variant="outline-danger" ok-variant="outline-success" centered hide-header no-close-on-esc no-close-on-backdrop >
         <div class="d-block text-center">
           <div>{{ modalObject.message }}</div>
         </div>
       </b-modal>
  </div>    
</template>
<script>
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import { Event } from "vue-tables-2";

export default {
  name: "Areas",
  components: {
    cSwitch,
    Event
  },
  data() {
    return {
      commontext: {
        submitButton: "Submit"
      },
      area: {
        AreaID: null,
        AreaName: "",
        PostalCode: "",
        Flag: 1,
        CityID: 0,
        AreaIsActive: true
      },
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      CountryID: 0,
      StateID: 0,
      countries: [],
      states: [],
      cities: [],
      columns: ["AreaName", "AreaIsActive", "AreaID"],
      data: [],
      options: {
        headings: {
          AreaName: "Area Name",
          AreaIsActive: "Status",
          AreaID: "Edit"
        },
        sortable: ["AreaName", "CityName"],
        filterable: ["AreaName", "CityName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default"
    };
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    getCountries: function() {
      // let res = await MasterService.getCountriesDDL();
      MasterService.getCountriesDDL()
        .then(response => {
          const { data } = response;
          if (data.Status && data.CountryList.length > 0) {
            this.countries = data.CountryList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm: function() {
      this.commontext.submitButton = "Submit";
      this.data = [];
      this.area = {
        AreaID: null,
        Flag: 1,
        AreaName: "",
        PostalCode: "",
        CityID: 0,
        AreaIsActive: true
      };
      this.CountryID = 0;
      this.StateID = 0;
      this.states = [];
      this.cities = [];
    },
    getStateList: function(CountryID) {
      this.states = [];
      this.cities = [];
      this.StateID = 0;
      this.area.CityID = 0;

      let payload = {
        CountryID
      };
      this.cities = [];
      MasterService.getStatesDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.StateList.length > 0) {
            this.states = data.StateList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCityList: function(StateID) {
      this.cities = [];
      this.area.CityID = 0;

      let payload = {
        StateID
      };
      MasterService.getCitiesDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CityList.length > 0) {
            this.cities = data.CityList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getAreaList: function(CityID) {
      // let res = await MasterService.getAreas(e);
      // this.data = res.data;
      this.data = [];
      let payload = {
        CityID
      };
      MasterService.getAreas(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.AreaList.length > 0) {
            this.data = data.AreaList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    validation: function() {
      if (this.CountryID == "") {
        this.showMessageSnackbar("Please select the Country.");
        return false;
      } else if (this.StateID == 0) {
        this.showMessageSnackbar("Please select a State.");
        return false;
      } else if (this.area.CityID == 0) {
        this.showMessageSnackbar("Please select a City.");
        return false;
      } else if (this.area.AreaName == "") {
        this.showMessageSnackbar("Area Name Cannot be empty.");
        return false;
      } else if (this.area.PostalCode == "") {
        this.showMessageSnackbar("Area Postal Code Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.area.CityID != 0) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      } else {
        this.showSubmitConfirmation("Select a City");
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");
      MasterService.addArea(this.area)
        .then(response => {
          const { data } = response;
          this.getAreaList(this.area.CityID);
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.resetForm();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editArea: function(AreaID) {
      // let res = await MasterService.getAreaByID(AreaID);
      let payload = {
        AreaID
      };
      MasterService.getAreaByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.AreaList.length > 0) {
            this.area = data.AreaList[0];
            this.area.Flag = 2;
            this.commontext.submitButton = "Update Area";
            this.scrollToTop();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    changeStatus: function(ID) {
      // let res = await MasterService.activateOrDeactivate(ID, "AreaMaster");
      let payload = {
        TableName: "AreaMaster",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>
<style>
.fa-edit {
  cursor: pointer;
  font-size: 18px;
}
.fa-edit:hover {
  color: #20a8d8;
}
</style>
